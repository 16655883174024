import { css } from "goober";

export const Container = () => css`
  display: grid;
  gap: 2rem;
  padding-block: 2rem;

  header {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }
  .list {
    display: flex;
    gap: 0.5rem;
  }

  .summoner-spells {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
  }

  .separator {
    align-items: center;
    background: var(--shade9);
    height: var(--sp-5);
    justify-content: center;
    width: var(--sp-6);
    margin-top: var(--sp-4);
  }
`;
