import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { Container } from "@/feature-reskin-probuilds-net/components/GuideBuild.style.jsx";
import AbilitiesSmall from "@/game-lol/components/AbilitiesSmall.jsx";
import ItemImage from "@/game-lol/components/ItemImg.jsx";
import ItemsContainer from "@/game-lol/components/ItemsContainer.jsx";
import RuneTree from "@/game-lol/components/RuneTree.jsx";
import SpellImg from "@/game-lol/components/SpellImg.jsx";
import { CHAMPIONS } from "@/game-lol/constants/champion-classes.mjs";
import useStaticChampionByKey from "@/game-lol/utils/use-static-champion-by-id.mjs";
import ChevronRight from "@/inline-assets/chevron-right.svg";

function GuideBuild({ matchPlayer, timelinePlayer, timeline }) {
  const { t } = useTranslation();

  const championId = matchPlayer?.championId;
  const championKey = CHAMPIONS[championId]?.key;
  const champion = useStaticChampionByKey(championKey);

  if (!matchPlayer) return null;

  const {
    item0,
    item1,
    item2,
    item3,
    item4,
    item5,
    item6,
    summoner1Id,
    summoner2Id,
    perks = {},
  } = matchPlayer;
  const items = [item0, item1, item2, item3, item4, item5, item6].filter(
    Boolean,
  );

  const timelineSkills = [];
  const purchases = [];
  for (const frame of timeline?.frames || []) {
    for (const event of frame.events || []) {
      if (event.participantId !== timelinePlayer?.participantId) continue;
      if (event.type === "ITEM_PURCHASED") {
        purchases.push({ itemId: event.itemId, timestamp: event.timestamp });
      }
      if (event.type === "SKILL_LEVEL_UP") {
        timelineSkills.push(event.skillSlot);
      }
    }
  }

  const buildOrder = [];

  let lastTs = 0;
  let lastObj = { ids: [], ts: 0 };
  if (purchases.length) {
    purchases.sort((a, b) => a.timestamp - b.timestamp);
    for (const item of purchases) {
      const id = item.itemId;
      const ts = item.timestamp;
      if (lastTs === 0) {
        lastObj = { ts, ids: [id] };
        lastTs = ts;
        continue;
      }
      if (ts - lastTs > 15000) {
        buildOrder.push(lastObj);
        lastObj = { ts, ids: [id] };
        lastTs = ts;
      } else {
        lastObj.ids = [...lastObj.ids, id];
        lastTs = ts;
      }
    }
    buildOrder.push(lastObj);
  }

  const primaryTree =
    (perks.styles || []).find((s) => s.description === "primaryStyle") || {};
  const secondaryTree =
    (perks.styles || []).find((s) => s.description === "subStyle") || {};
  const primaryRunes = (primaryTree?.selections || []).map((r) => r.perk) || [];
  const secondaryRunes =
    (secondaryTree?.selections || []).map((r) => r.perk) || [];
  const playerRunes = [
    primaryTree.style,
    ...primaryRunes,
    secondaryTree.style,
    ...secondaryRunes,
  ].filter(Boolean);
  const statPerks = perks.statPerks || {};
  const playerStatShards = [
    statPerks.offense,
    statPerks.flex,
    statPerks.defense,
  ].filter(Boolean);

  return (
    <div className={Container()}>
      <header>
        {items.length ? (
          <div>
            <h3 className="type-page-header">{t("", "Items")}</h3>
            <h4 className="type-page-subheader shade1">
              {t("", "Final Build")}
            </h4>
            <div className="list">
              {new Array(6).fill(0).map((_, i) => {
                const itemId = items[i];
                return (
                  <ItemImage key={`${itemId}_${i}`} itemId={itemId} size={4} />
                );
              })}
            </div>
          </div>
        ) : null}
        {summoner1Id || summoner2Id ? (
          <div className="summoner-spells">
            <h3 className="type-page-header">{t("", "Summoner Spells")}</h3>
            <h4
              className="type-page-subheader shade1"
              style={{ opacity: 0, visibility: "hidden" }}
            >
              {"-"}
            </h4>
            <div className="list">
              {[summoner1Id, summoner2Id].map((id, i) => (
                <SpellImg key={`${id}_${i}`} spellId={id} size={4} />
              ))}
            </div>
          </div>
        ) : null}
      </header>
      {timelinePlayer && buildOrder.length ? (
        <section className="build-order">
          <ItemsContainer
            items={buildOrder}
            hideTitle={false}
            hideWinRate={true}
            wins={0}
            games={0}
            itemTitle={t("lol:championData.buildPath", "Build Path") + ":"}
            itemContainerStyle={{
              marginBottom: "8px",
            }}
            size={2.25}
            renderSeparator={() => {
              return (
                <div className="separator">
                  <ChevronRight />
                </div>
              );
            }}
            itemOrder={null}
            itemStyle={null}
            patch={null}
          />
        </section>
      ) : null}
      {timelineSkills.length ? (
        <section>
          <h3 className="type-page-header">{t("", "Abilities")}</h3>
          <Card>
            <AbilitiesSmall
              showSkillOrder
              champion={champion}
              skills={timelineSkills}
              probuildsTab={false}
            />
          </Card>
        </section>
      ) : null}
      {playerRunes.length ? (
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h3
            className="type-page-header"
            style={{ textAlign: "center", paddingBlockEnd: "1rem" }}
          >
            {t("", "Runes")}
          </h3>
          <div style={{ maxWidth: 600, width: "100%" }}>
            <RuneTree
              runesList={playerRunes}
              shardsList={playerStatShards}
              runesStats={{}}
              buildGames={1}
            />
          </div>
        </section>
      ) : null}
    </div>
  );
}

export default GuideBuild;
